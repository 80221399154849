// import axios from "axios";
// import { DOMAIN_NAME } from "../values/DomainName";
import { message } from "antd";
import { gapi } from "gapi-script";
import { CONVERT_SCROLLERVIDEOS_TO_URL_AND_NAMES, createFolder } from "../values/Helpers";
import JSZip from 'jszip'; 

export const API_HANDLE_DOWNLOAD_TO_DRIVE = async (
    setShowSpinner,
    scrollerVideos,
    projectDetails,
    SCOPES,
    client_id,
    redirect_uri,
    setDOWNLOAD_TO_DRIVE_STARTED,
    setDriveLink
  ) => {
    try {
      // Convert scrollerVideos to URLs and custom names
      const { outputVideoPaths, customNames } = CONVERT_SCROLLERVIDEOS_TO_URL_AND_NAMES(scrollerVideos, projectDetails);
      
      // Load the gapi client library
      await gapi.load("client:auth2", async () => {
        await gapi.client.init({
          clientId: client_id,
          scope: SCOPES,
          redirectUri: redirect_uri,
        });
  
        const GoogleAuth = gapi.auth2.getAuthInstance();
        
        // Attempt to sign in the user
        try {
          await GoogleAuth.signIn();
          
          const accessToken = GoogleAuth.currentUser
            .get()
            .getAuthResponse().access_token;
  
          try {
            // Create a new folder in Google Drive for this project
            const folderId = await createFolder(`${projectDetails.name}`, accessToken);
            
            setDOWNLOAD_TO_DRIVE_STARTED(true);  // Mark the upload process as started
            
            // Loop through each video and upload it to Google Drive
            for (let i = 0; i < outputVideoPaths.length; i++) {
              const videoUrl = outputVideoPaths[i];  // This is the S3 URL
              const customName = customNames[i];  // Get the custom name for the file
      
              // Fetch the video file from the S3 URL
              const response = await fetch(videoUrl);
              if (!response.ok) {
                continue;  // Skip to the next video if there's an issue
              }
  
              const blob = await response.blob(); // Convert the response to a Blob (file)
      
              // Set the metadata for the file
              const metadata = {
                name: customName,
                mimeType: "video/*",  // You can specify more specific MIME types like video/mp4
                parents: [folderId],
              };
      
              // Prepare the FormData for the file upload
              const form = new FormData();
              form.append(
                "metadata",
                new Blob([JSON.stringify(metadata)], { type: "application/json" })
              );
              form.append("file", blob);
      
              // Perform the upload to Google Drive
              const uploadResponse = await fetch(
                "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
                {
                  method: "POST",
                  headers: new Headers({
                    Authorization: "Bearer " + accessToken,  // Include the access token in the headers
                  }),
                  body: form,  // The form contains both metadata and the file (video Blob in this case)
                }
              );
      
              // Check if the upload was successful
              if (!uploadResponse.ok) {
                const errorText = await uploadResponse.text();
                throw new Error(`Failed to upload ${customName}: ${errorText}`);
              }
            }
      
            // After all files are uploaded, generate the folder link
            const folderLink = `https://drive.google.com/drive/folders/${folderId}`;
      
            // Notify the user that the upload was successful
            message.success("Videos uploaded to Google Drive successfully!");
            setDriveLink(folderLink);  // Set the drive link so it can be displayed
      
          } catch (error) {
            message.error("An error occurred while uploading videos.");
          } finally {
            // Cleanup spinner and upload state
            setShowSpinner(false);
            setDOWNLOAD_TO_DRIVE_STARTED(false);
          }
        } catch (signInError) {
          message.error('Failed to sign in to Google account.');
        }
      });
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      message.error(errorMessage);
    }
  };
  
  
  export const API_HANDLE_DOWNLOAD_TO_PC = async (scrollerVideos, fileName, created_at, setShowSpinner) => {
    setShowSpinner(true);
    let outputVideoPaths = [];
    let outputVideoNames = [];
  
    scrollerVideos.forEach(video => {
      // Here we are using the full URL directly from the video fields
      if (video.output_video) {
        outputVideoPaths.push(video.output_video); // Direct S3 URL
        console.log(video.output_video.split('/').pop());  // Log the filename
      } else {
        console.error("video.output_video is null or undefined:", video);
      }
  
      if (video.output_gif) {
        outputVideoPaths.push(video.output_gif); // Direct S3 URL
      } else {
        console.error("video.output_gif is null or undefined:", video);
      }
  
      // Construct the name for the ZIP file from the video info
      outputVideoNames.push(`${fileName}-${created_at}-${video.web_url}.mp4`);
      outputVideoNames.push(`${fileName}-${created_at}-${video.web_url}.gif`);
    });
  
    // Fetch videos as ZIP from the direct S3 URLs
    downloadVideosAsZip(outputVideoPaths, outputVideoNames, fileName, setShowSpinner);
  };
  
  function downloadVideosAsZip(videoPaths, outputVideoNames, fileName, setShowSpinner) {
    const zipVideos = new JSZip();
    let processedCount = 0;
  
    const fetchPromises = videoPaths.map((videoPath, index) => {
      return fetch(videoPath)  // Direct fetch from the S3 URL
        .then(response => {
          if (!response.ok) {
            throw new Error(`Failed to fetch video: ${response.statusText}`);
          }
          return response.blob();
        })
        .then(videoBlob => {
          const videoName = outputVideoNames[index];
          zipVideos.file(videoName, videoBlob);  // Add video to the ZIP
        })
        .catch(error => console.error('Error downloading video:', error))
        .finally(() => {
          processedCount++;
          // Once all videos are processed, generate the ZIP
          if (processedCount === videoPaths.length) {
            generateZip(zipVideos, fileName, setShowSpinner);
          }
        });
    });
  
    // Generate the ZIP file and trigger the download
    function generateZip(zip, name, setShowSpinner) {
      zip.generateAsync({ type: 'blob' })
        .then(content => {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.display = 'none';
          const url = window.URL.createObjectURL(content);
          a.href = url;
          a.download = `${name}.zip`;  // File name for the ZIP download
          a.click();  // Trigger the download
          window.URL.revokeObjectURL(url);  // Clean up the object URL
          document.body.removeChild(a);  // Remove the link element
          setShowSpinner(false);  // Hide the spinner after download is complete
        })
        .catch(error => console.error('Error generating zip folder:', error));
    }
  
    return Promise.all(fetchPromises);  // Wait for all fetches to complete
  }
  