import React from "react";
import { useState } from "react";
import logoImage from "../assets/logo.png";
import { useEffect } from "react";
import './styles/Footer.css';
import { useNavigate, useLocation } from "react-router-dom";
import FeedbackForms from "./FeedbackForms";
import { Button } from "antd";
import faqarrow from '../assets/faqarrow.svg'

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Type_of_modal, setType_of_modal] = useState('');

  const showModal = (type) => () => {
    setType_of_modal(type);
    setIsModalOpen(true);
  };


const handleNavigation = (path) => () => {
  const [basePath, hash] = path.split("#");

  if (hash) {
    if (location.pathname === basePath) {
      document.getElementById(hash)?.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate(basePath, { state: { hash } });
    }
  } else {
    navigate(path);
  }
};

// To handle scrolling after navigation
useEffect(() => {
    const hash = location.state?.hash;
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
  
        // Scroll 40px more after the smooth scroll is done
        setTimeout(() => {
          window.scrollBy(0, 40);
        }, 500); // Adjust timeout to match the duration of `scrollIntoView`
      }
    }
  }, [location]);

  return (
    <div className="row m-0">
      <div className="col-12">
        <div className="row m-0 footer pt-3">
          <div className="col-12  col-md-6 col-lg-4 col-xxl-3">
            <img src={logoImage} alt="" className="footer-logo" />
            <h5 className="footer-logo-heading">Stand out in the inbox with eye-catching videos</h5>
            <p className="footer-item-contact"> Support: <a href="tel:+37061476707"><u>+370 6147 6707</u></a> </p>
            <p className="footer-item"> <a href="mailto:hi@cybervisionaries.com"><u>hi@cybervisionaries.com</u></a> </p>
            
          </div>
          {/* <div className="col-1 d-none d-xxl-block"></div> */}
          <div className="col-12 col-md-6 col-lg-3 col-xxl-3 mt-2">
            <ul className="p-0">
              <li className="footer-item" onClick={handleNavigation('/pricing')}>Pricing</li>
              <li className="footer-item" onClick={handleNavigation(window.location.href.includes('pricing') ? '/#pricing_faq' : '/#home_faq')}>FAQs</li>
              <li className="footer-item" onClick={handleNavigation('/#testimonials')}>Testimonials</li>
              <li className="footer-item" onClick={showModal("apply_affiliate")}>Affiliate Program</li>
              <li className="footer-item" onClick={showModal("contact")}>Contact Us</li>
            </ul>
          </div>
          <div className="col-12 col-md-6 col-lg-3 col-xxl-3 mt-2">
            <ul className="p-0">
              <li className="footer-item" onClick={handleNavigation('/termsOfService')}>Terms of Service</li>
              <li className="footer-item" onClick={handleNavigation('/privacyPolicy')}>Privacy Policy</li>
              <li className="footer-item" onClick={handleNavigation('/refundPolicy')}>Refund Policy</li>
            </ul>
          </div>
          <div className="col-12 col-md-6 col-lg-2  col-xxl-2 mb-3 mt-2">
            <ul className="p-0">
              <li className="footer-item" onClick={handleNavigation('/account')}>My Account</li>
              <li className="footer-item" onClick={showModal("report")}>Report a bug</li>
              <li className="footer-item" onClick={showModal("feedback")}>Leave feedback</li>
            </ul>
          </div>
          <div className="col-1 d-none d-xxl-block">
            <Button className="top-move-icon" onClick={()=>window.scrollTo(0,0)}><img src={faqarrow}></img></Button>
          </div>
        </div>
      </div>
      <div className="col-12 text-center">
      <p className="footer-item-bottom">© {new Date().getFullYear()} Cyber Visionaries. All rights reserved.</p>
      </div>
      {isModalOpen && <FeedbackForms isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} typeOfModal={Type_of_modal} />}
    </div>
  );
};

export default Footer;
