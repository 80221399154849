import React, { useEffect, useState} from "react";
import "./styles/HomePageComponent.css";
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import faqarrow from '../assets/faqarrow.svg'
import FeedbackForms from "../components/FeedbackForms";
const { Panel } = Collapse;

export const TESTIMONIALS = [
  {
    name: "John ", description: "This tool is a life saver for our sales team. Tried it for a couple of months, loved it and now we use with Make, which attaches these videos to emails automatically - I could not ask for more. This is amazing, thank you! " },
  {
    name: "Chris", description: "Comfortable user experience, UI is slick. Great price for a tool of this level!" },
  {
    name: "Hollie", description: "We never received so many answers from cold leads right after sending out emails. I wish i could have though of this strategy earlier! Great tool, thanks.", },
  {
    name: "Phil", description: "The results speak for themselves. We’re seeing higher engagement and more successful conversions than ever before. Can’t imagine going back to cold outreach without personalized videos.", },
  {
    name: "Violet", description: "Exceptional tool! The automation capabilities are superb, and the app itself is incredibly user-friendly. It has made managing our cold outreach videos a lot more efficient.", },
  {
    name: "Valdo", description: "The results with this tool have been fantastic. Our cold outreach conversion rates have improved significantly. It’s easy to use, and the automation has made a real difference in our outreach costs.", },
  {
    name: "Luke", description: "Couldn’t find a better bulk video creator for the budget. There is competition, but none of it comes even close to the speeds and prices of Cyber Visionaries tool. Thanks. ", },
  {
    name: "Linkoln", description: "We saw an instant improvement in our conversion rates after starting to use this tool for our cold outreach. Hope to see more functions regarding team management, but for the price - best option in the market.", },
  {
    name: "Sara", description: "We needed a boost in our conversion rates, and this tool delivered. The ease of automation and video management has helped us connect better with high-ticker prospects and we see a substantial increase in responses.", },
];

export const FAQ = ({show = "home", navigate, isLoggedIn}) => {

    
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (type) => () => {
    setIsModalOpen(true);
  };

    const [activeKey, setActiveKey] = useState([]);

    const handlePanelChange = (key) => {
      setActiveKey(key);
    };
  
    const isActive = (key) => activeKey.includes(key);
  return (
  <div className="row m-0 p-0" name={`${window?.location?.href.includes('pricing')?'pricing_faq':'home_faq'}`}>
    <h2 className={`faq_heading ps-1`}>FAQs</h2>
    <p className={`description ps-1`}>
      Everything you need to know about the product and billing.{" "}
    </p>
    <div className="row justify-content-center m-0 p-1">
      <div className="col-12 p-0">
      <Collapse
          activeKey={activeKey}
          onChange={handlePanelChange}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <img
              src={faqarrow}
              alt="arrow"
              className={`faq-arrow ${isActive ? 'rotate' : ''}`}
            />
          )}
          accordion
          bordered={false}
        >
          <Panel
            header="Is there a free plan available?"
            key="1"
            className={`faq-panel mb-3 ${isActive('1') ? 'active' : ''}`}
          >
            <p>Yes, you can use the video creator tool for free with Basic plan.</p>
          </Panel>
          <Panel
            header="Can I change my plan later?"
            key="2"
            className={`faq-panel ${isActive('2') ? 'active' : ''}`}
          >
            <p>Of course. Our pricing scales with your company. Choose one of the available plans or chat to our team to find a solution that works for you.</p>
          </Panel>
          <Panel
            header="How can I redeem a serial code?"
            key="11"
            className={`faq-panel ${isActive('11') ? 'active' : ''}`}
          >
           <p>To redeem a 16-digit serial code, follow these steps: <br/>
                1. Create a free account.<br/>
                2. Verify your email address.<br/>
                3. Log in to your account.<br/>
                4. In the left navigation menu, click on "Redeem Serial Code."<br/>
                5. Follow the on-screen instructions to complete the redemption process.</p>
           </Panel>
          <Panel
            header="I prefer not to be on camera. Could you record the video for me?"
            key="3"
            className={`faq-panel ${isActive('3') ? 'active' : ''}`}
          >
            <p>Our professional actors can create a fully personalized video based on your script for a one-time fee. This service can also include scriptwriting for a hassle-free experience. <a onClick={()=>setIsModalOpen(true)} style={{color:"rgba(0, 113, 159, 1)"}}>Contact us</a> to receive a quote for your project.</p>
          </Panel>
          <Panel
            header="I ran out of my plan’s prospecting videos’ credits. What can I do now?"
            key="4"
            className={`faq-panel ${isActive('4') ? 'active' : ''}`}
          >
            <p>If you run out of prospecting videos for your chosen plan, you can either upgrade your plan or buy a set amount of credits for a fixed rate by going to account <i className="fa-solid fa-arrow-right"></i> My plans <i className="fa-solid fa-arrow-right"></i> Get more credits.</p>
          </Panel>
          <Panel
            header="What is your cancellation policy?"
            key="5"
            className={`faq-panel ${isActive('5') ? 'active' : ''}`}
          >
            <p>We understand that things change. You can cancel your plan at any time.</p>
          </Panel>
          <Panel
            header="Can other info be added to an invoice?"
            key="6"
            className={`faq-panel ${isActive('6') ? 'active' : ''}`}
          >
            <p>We cannot edit the invoice information - invoices are generated by Stripe.</p>
          </Panel>
          <Panel
            header="How does it work?"
            key="7"
            className={`faq-panel ${isActive('7') ? 'active' : ''}`}
          >
            <p>You can import a list of prospects' website URLs, record a short video message, edit how the recording will look and our algorithm will automatically generate personalized videos for each prospect. These videos can be sent via email or LinkedIn, or exported to Google Drive for use with your preferred outreach tool.</p>
          </Panel>
          <Panel
            header= <h3 style={{fontFamily: '"Inter", sans-serif', fontSize: '26px', fontWeight: 600}}> What are the key benefits of blending videos with dynamic web pages? </h3>
            key="8"
            className={`faq-panel ${isActive('8') ? 'active' : ''}`}
          >
            <p>
              + Time-saving automation<br />
              + Personalized outreach video creation at scale<br />
              + Increased lead engagement<br />
              + Improved response and meeting conversion rates
            </p>
          </Panel>
          <Panel
            header="How can you help me book more meetings with prospects?"
            key="9"
            className={`faq-panel ${isActive('9') ? 'active' : ''}`}
          >
            <p>By incorporating personalized videos into your outreach, your emails capture more attention, build rapport, and increase response rates, leading to more meetings with your target prospects.</p>
          </Panel>
          <Panel
            header="How can you help streamline my prospecting process?"
            key="10"
            className={`faq-panel ${isActive('10') ? 'active' : ''}`}
          >
           <p>We automate the creation of personalized videos, allowing you to reach out to prospects at scale and save valuable time on repetitive tasks. You can export your videos to Google Drive and set up a campaign with your favorite outreach or automation tool.</p>
           </Panel>
          
          <Panel
            header=""
            key="12"
            className={`faq-panel d-none ${isActive('12') ? 'active' : ''}`}
          >
            <p>We automate the creation of personalized videos, allowing you to reach out to prospects at scale and save valuable time on repetitive tasks. You can export your videos to Google Drive and set up a campaign with your favorite outreach or automation tool.</p>
          </Panel>
        </Collapse>
      </div>
    </div>

    <div className="row justify-content-center m-0 p-0">
        <div className="col-12 p-0">
            <div className="row justify-content-between mt-3 cant-find-answer m-0">
            <div className="col-12 col-lg-6" style={{ display: 'flex', flexDirection: 'column' }}>
                <p className={`descriptionHeading pb-2 pb-md-1`}>Can’t find the answer you’re looking for?</p>
                <p className={`description`}>Get in touch with our friendly team, we would love to chat!</p>
            
            </div>
            <div className="col-12 col-lg-6 text-end">
                <button className="home_page_btn_filled_touch" style={{ marginTop: '1rem' }} onClick={showModal()}>Get in Touch</button>
            </div>
        </div>
        </div>
    </div>
    {isModalOpen && <FeedbackForms isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} typeOfModal={"contact"} />}
{show == "home" && <div className="row justify-content-center mt-4 text-center m-0 p-0">
        <div className="col-12 col-md-9 record_video text-white" >
           <h5>Record 1 video - we’ll personalize it to 1000s</h5>
           <p>Start for free, no credit card required! You can upgrade or downgrade anytime. </p>
           <button className="home_page_btn_outlined_white" onClick={()=>navigate('/account')}>Get Started For Free</button>
        </div>
    </div>}
    {show == "pricing" && 
    <div className="row m-0 p-0 mt-4">
         <div className="row justify-content-center mt-5 m-0 record_video">
        <div className="col-12 col-md-9 text-white p-0 py-2" >
        <h5>Try out bulk video prospecting tool for FREE</h5>
        <p className="m-0">Create thousands of highly personalized prospecting videos in minutes, not hours.</p>
        
        </div>
        <div className="col-12 col-md-3 text-end align-self-center">
        <button className="home_page_btn_outlined_white_pricing" onClick={()=>{
            {isLoggedIn ? navigate('/projects') : navigate('/account?showSignup=true')}
        }}>Get Started</button>
        </div>
    </div>
    </div>
   }
  </div>)
};
